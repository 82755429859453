*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
    &-logo
      top: 15px !important
      @media screen and (max-width: 420px)
        top: 12px !important
      img
        width: 120px !important
        height: 120px !important
        @media screen and (max-width: 1100px)
          width: 100px !important
          height: 100px !important
        @media screen and (max-width: 420px)
          width: 60px !important
          height: 60px !important
    &-application
      .widget-tab__panorama-map__fov-dot
        box-shadow: none !important
  .floors-select--block-item--active
    color: #FFFFFF

.widget-tab__panorama-caption
  position: absolute
  bottom: 120px
  left: 50%
  transform: translate(-50%, -50%)
  color: white !important
  font-size: 11px
  @media screen and (max-width: 670px)
    bottom: 70px
  @media screen and (max-width: 420px)
    font-size: 9px
    left: 56%
    bottom: 60px
