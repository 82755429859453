*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .widget-logo {
  top: 15px !important;
}

@media screen and (width <= 420px) {
  .widget-section .widget-logo {
    top: 12px !important;
  }
}

.widget-section .widget-logo img {
  width: 120px !important;
  height: 120px !important;
}

@media screen and (width <= 1100px) {
  .widget-section .widget-logo img {
    width: 100px !important;
    height: 100px !important;
  }
}

@media screen and (width <= 420px) {
  .widget-section .widget-logo img {
    width: 60px !important;
    height: 60px !important;
  }
}

.widget-section .widget-application .widget-tab__panorama-map__fov-dot {
  box-shadow: none !important;
}

.widget-section .floors-select--block-item--active {
  color: #fff;
}

.widget-tab__panorama-caption {
  font-size: 11px;
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff !important;
}

@media screen and (width <= 670px) {
  .widget-tab__panorama-caption {
    bottom: 70px;
  }
}

@media screen and (width <= 420px) {
  .widget-tab__panorama-caption {
    font-size: 9px;
    bottom: 60px;
    left: 56%;
  }
}
/*# sourceMappingURL=index.70e171b3.css.map */
